import React, { useState } from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Loader,
  Modal,
  Alert,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import Link from "next/link";
// import IntlMessages from "../../../util/IntlMessages";
import { useAuth } from "../../../util/use-auth";
import { useDispatch, useSelector } from "react-redux";
import { updateStatusIdle } from "@/redux/actions";
import { TbAlertCircle } from "react-icons/tb";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 900,
    backgroundSize: "cover",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 900,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Login = (props) => {
  const { isLoading, userLogin, error } = useAuth();
  const [autoLogoutModal, setAutoLogoutModal] = useState(false);
  const idle = useSelector(({ status }) => status.idle);
  const dispatch = useDispatch();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    userLogin(values, null);
  };
  const form = useForm({});

  const { classes } = useStyles();
  return (
    <>
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className={classes.wrapper}>
              <form onSubmit={form.onSubmit((values) => onFinish(values))}>
                <Paper className={classes.form} radius={0} p={30}>
                  <Title
                    order={2}
                    className={classes.title}
                    align="center"
                    mt="md"
                    mb={50}
                  >
                    Welcome to Vrdemocrat
                  </Title>

                  <TextInput
                    required
                    label="Username"
                    placeholder="hello@gmail.com"
                    size="md"
                    {...form.getInputProps("username")}
                  />
                  <PasswordInput
                    required
                    label="Password"
                    placeholder="Your password"
                    mt="md"
                    size="md"
                    {...form.getInputProps("password")}
                  />
                  <Button
                    fullWidth
                    mt="xl"
                    size="md"
                    type="submit"
                    loading={isLoading}
                  >
                    Login
                  </Button>
                </Paper>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        opened={idle}
        withCloseButton={false}
        trapFocus={false}
        onClose={() => dispatch(updateStatusIdle(false))}
        padding={0}
      >
        <Alert
          icon={<TbAlertCircle size={16} />}
          color="red"
          withCloseButton
          closeButtonLabel="ปิด"
          onClose={() => dispatch(updateStatusIdle(false))}
        >
          ออกจากระบบอัตโนมัติเนื่องจากไม่มีการใช้งาน
        </Alert>
      </Modal>
    </>
  );
};

export default Login;
