import React from "react";
import LoginPage from "./login";
import { useAuth } from "../util/use-auth";
import DashboardPage from "./dashboard";

const HomePage = () => {
  const { authUser } = useAuth();

  return authUser ? <DashboardPage /> : <LoginPage />;
};

export default HomePage;
