import React from "react";
import asyncComponent from "@/util/asyncComponent";
import Head from "next/head";

import Login from "@/routes/userAuth/Login";

const LoginPage = () => (
  <React.Fragment>
    <Head>
      <title>Vrdemocrat</title>
    </Head>
    <Login />
  </React.Fragment>
);
export default LoginPage;
